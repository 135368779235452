header.header {
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    &.opened {
        position: absolute;
    }

    &.with-lang-switch {
        padding-top: 40px;
    }

    .container-header {
        max-width: 1320px;
    }

    .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo-container {
        display: flex;
        align-items: center;

        a {
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                width: 100%;
            }
        }

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
        }

        .logo-investors {
            border-right: 1px solid $light-gray;
            height: 49px;
            padding-right: 16px;
            margin-right: 16px;

            @include mobile {
                border: 0;
                margin-bottom: 16px;
                padding-right: 0;
                margin-right: 0;
            }
        }

        .logo-caption {
            color: $red;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.25;

            &::first-line {
                color: $dark-gray;
            }
        }
    }

    .header-right {
        display: flex;
        justify-content: flex-end;
    }

    .buttons {
        display: flex;
        font-size: 14px;
        font-weight: bold;
        list-style: none;
        margin: 0;
        padding: 0;

        @media (max-width: 1280px) {
            font-size: 12px;
        }

        @include tablet() {
            background-color: rgba($white, .98);
            box-shadow: 0px 10px 5px 0px rgba($black, .25);
            display: none;
            height: auto;
            justify-content: center;
            padding: 12px;
            text-align: center;
            width: 100%;
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 99;
        }

        > li {
            height: 100%;
            position: relative;

            @include tablet() {
                height: auto;
            }

            &:not(:last-child) {
                margin-right: 50px;

                @media (max-width: 1280px) {
                    margin-right: 40px;
                }

                @media (max-width: 1180px) {
                    margin-right: 20px;
                }

                @include tablet() {
                    margin-right: 0;
                }
            }

            &.ext-logo {

                .ext-logo-img {
                    width: 120px;
                    height: auto;
                }
            }

            > a::before {
                background-color: $red;
                content: '';
                height: 8px;
                left: 0;
                top: -20px;
                opacity: 0;
                position: absolute;
                @include transition-fast();
                width: 100%;

                @include tablet {
                    top: 0;
                }
            }

            &:hover {

                > a::before {
                    opacity: 1;
                }
            }

            > a {
                color: black;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                height: 100%;
                position: relative;

                @include tablet() {
                    display: inline-block;
                    height: auto;
                    padding: 8px 0;
                    margin: 8px auto;
                }
            }

            &:hover ul.submenu {
                display: block;
            }

        }

        ul.submenu {
            width: 100%;
            z-index: 99;

            @include laptop-and-greater() {
                background-color: #ffffff;
                box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.2);
                position: absolute;
                padding: 20px 0;
                top: 100%;
                left: -60px;
                width: calc(100% + 200px);
                height: auto;
                display: none;

                .wrapper {
                    height: 100%;
                    padding: 0 40px;
                }
            }

            li {
                padding: 10px 0;

                a {
                    cursor: pointer;
                    display: block;
                    font-weight: bold;
                    font-size: 15px;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.53;
                    letter-spacing: normal;
                    color: rgba(0, 0, 0, 0.5);
                    text-align: center;
                    transition: .3s ease-in-out;

                    @include laptop-and-greater {
                        text-align: left;
                    }

                    @media (max-width: 1280px) {
                        font-size: 12px;
                    }

                    &:hover {
                        color: #000;
                    }
                }
            }
        }
    }

    .lang-switch {
        position: absolute;
        left: 225px;
        top: -24px;
        display: flex;
        //display: none;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;

        @include mobile {
            left: auto;
            right: 15px;
        }

        &:hover {
            .lang-wrapper {
                box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.2);
                height: 48px;
            }
        }

        .arrow {
            margin-top: 8px;
        }

        .lang-wrapper {
            background-color: $white;
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 24px;
            width: 24px;
            overflow: hidden;
            position: relative;
            @include transition-fast;
        }

        .lang {
            position: absolute;
            top: 24px;

            &.active {
                top: 0;
            }
        }

        a {
            color: #bfbfbf;
            font-weight: bolder;
            text-transform: uppercase;

            &:hover {
                color: #707070;
            }
        }

        img {
            margin-left: 5px;
        }
    }

    .hamburger-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        order: 2;
        @include laptop-and-greater {
            display: none;
        }

        @include mobile {
            align-self: flex-start;
            margin-top: 12px;
        }

        > span {
            color: $almost-black;
            font-size: 3.2rem;
            font-family: 'mm_headline_webttregular', sans-serif;
            margin-right: 12px;
        }

        .hamburger {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 24px;

            .inner,
            .inner::after,
            .inner::before {
                position: absolute;
                width: 30px;
                height: 4px;
                transition-timing-function: ease;
                transition-duration: .15s;
                transition-property: transform;
                background-color: #000;
            }

            .inner {
                top: 50%;
                display: block;
                margin-top: -2px;
                transition-timing-function: cubic-bezier(.55, .055, .675, .19);
                transition-duration: .22s;
            }

            .inner::before {
                display: block;
                content: "";
                top: -10px;
                transition: top .1s ease-in .25s, opacity .1s ease-in;
            }

            .inner::after {
                display: block;
                content: "";
                bottom: -10px;
                transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
            }

            &.active {
                .inner {
                    transition-delay: .12s;
                    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
                    transform: rotate(225deg);
                }

                .inner::before {
                    top: 0;
                    transition: top .1s ease-out, opacity .1s ease-out .12s;
                    opacity: 0;
                }

                .inner::after {
                    bottom: 0;
                    transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
                    transform: rotate(-90deg);
                }
            }
        }
    }
}
