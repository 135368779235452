footer.footer {
    background-color: $gray-bg;

    .footer-content {
        display: flex;
        align-items: center;
        padding-top: 60px;
        padding-bottom: 60px;

        @include mobile() {
            flex-direction: column;
            align-items: flex-start;
        }

        .section-title {
            margin-right: 80px;

            @include mobile() {
                margin-right: 0;
                margin-bottom: 30px;
                width: 100%;
            }
        }

        p {            

            a {
                color: $light-red;
                font-weight: bold;
            }
        }
    }
}

.disclaimer {
    margin-top: 40px;
    margin-bottom: 40px;

    p {
        font-size: 9px;
        font-weight: 500;
        color: $gray;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
