.homepage {

    .intro {
        padding-top: 80px;

        @include tablet {
            padding-top: 30px;
        }

        .section-title {
            margin-bottom: 50px;
        }

        ul.list-brand {
            margin-bottom: 30px;
        }

        .img-container {
            position: relative;
        }

        .ppk-img {
            margin-left: 10px;

            @media (max-width: 991px) {
                display: none;
            }
        }

        .intro-img {
            position: absolute;
            right: -50px;
            top: 180px;

            @media (max-width: 991px) {
                margin: 36px auto 0;
                width: 400px;
                display: none;
            }
        }
    }

    .movie {
        margin-top: 60px;

        .movie-play {
            background-position: center;
            background-repeat: no-repeat;            
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 880px;
            height: 550px;
            margin: 0 auto;

            @include mobile() {
                background-size: cover;
                height: 275px;
            }

            img {
                @include transition-fast;
            }

            &:hover img {
                transform: scale(1.25);
            }
        }
    }

    .people-decor {
        margin-top: 15px;
        margin-bottom: 95px;
        text-align: center;

        @include mobile {
            margin-bottom: 30px;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .savings {
        position: relative;

        p {
            font-size: 21px;
            font-weight: 500;
            letter-spacing: 0.4px;
            line-height: 1.6;
            margin-right: 30px;

            @include mobile {
                margin-right: 0;
            }
        }

        .savings-content {
            display: flex;
            align-items: flex-start;

            @include mobile {
                flex-direction: column;
            }
        }

        .coins-stacks {
            margin-left: 70px;
            margin-right: 45px;

            @include mobile {
                margin: auto;
            }
        }

        .savings-decor {
            margin-left: 10px;
            margin-right: 40px;
            margin-top: -35px;
            width: 170px;
            height: auto;

            @include mobile {
                margin-left: auto;
                margin-right: auto;
            }
        }

        p {
            margin-bottom: 50px;
            max-width: 790px;
            width: 100%;

            @include mobile {
                margin-top: 30px;
            }
        }

        .skew-bg {
            background-color: $gray-bg;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;

            @media (min-width: 768px) {
                background-color: transparent;
                background-image: url(../media/skew-bg.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                left: 60px;
                right: 20px;
                top: -65px;
                bottom: -40px;
            }
        }

        .breaked-line {
            position: absolute;
            left: 0;
            bottom: -55px;
            width: 100%;

            @include mobile {
                display: none;
            }
        }
    }

    .contributors {
        margin-top: 120px;
        margin-bottom: 115px;

        @include mobile {
            margin-top: 90px;
            margin-bottom: 70px;
        }

        .contributors-content {

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .text-columns {

        .ico-btn {
            margin-top: 30px;
        }
    }

    .benefits {

        .section-title {

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }

        .img-decor {
            margin-top: 10px;
            margin-bottom: 30px;
            margin-left: 40px;
            width: 100%;
            max-width: 370px;
            height: auto;

            @media (max-width: 991px) {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .calculations {
        margin-top: 70px;
        margin-bottom: 125px;

        @include tablet{
            margin-bottom: 70px;
        }

        .calc {
            text-align: center;

            img {
                width: 100%;
                max-width: 300px;
            }
        }

        .calc:not(:last-child) {

            @include mobile {
                margin-bottom: 30px;
            }
        }

        .calculations-label {
            color: #898989;
            font-size: 15px;
            margin-top: 70px;
        }

        .section-title{
            margin-bottom: 50px;
        }
    }

    .prizes {
        margin-bottom: 90px;

        .prizes-decor {
            width: 100%;
            max-width: 480px;
            height: auto;
            margin-top: 35px;

            @media (max-width: 991px) {
                display: block;
                margin: 0 auto;
                margin-bottom: 30px;
            }
        }

        .section-title {
            margin-bottom: 50px;
        }

        .plead {
            margin-bottom: 30px;
        }

        .prizes-list {
            margin-top: 60px;
        }

        .prize {
            text-align: center;

            &:not(:last-child) {

                @include mobile {
                    margin-bottom: 30px;
                }
            }

            img {
                display: block;
                margin: 0 auto;
                margin-bottom: 20px;
            }
        }
    }

    .faq {
        margin-bottom: 50px;

        .section-title {

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }

        ul {
            margin-top: 30px;

            br {
                display: none;
            }
        }
    }

    .qanda {
        margin-bottom: 170px;

        @include mobile {
            margin-bottom: 85px;
        }

        #main-tabs {
            margin-bottom: 50px;
            border-bottom: 2px solid $gray;
            @media (max-width: 1024px) {
                width: 100%;
                border: none;
            }

            > .nav-link {
                background-color: transparent;
                color: $almost-black;
                border-radius: 0;
                padding: 30px 20px 0 20px;
                display: flex;
                align-items: flex-start;
                border: 2px solid transparent;
                border-bottom: 0;
                margin: 0 0 -2px 0;
                @media (max-width: 1024px) {
                    width: 100%;
                    border: none;
                    border-bottom: 2px solid transparent;
                }

                p {
                    margin: 0;
                    font-size: 24px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: rgba(17, 17, 17, 0.2);
                    padding-bottom: 11px;

                    br {

                        @media (max-width: 1024px) {
                            display: none;
                        }
                    }

                }

                &.active {
                    background-color: #fff;
                    border-color: $gray;
                    @media (max-width: 1024px) {
                        border: none;
                        border-bottom: 2px solid $gray;
                    }

                    p {
                        color: $almost-black;
                    }
                }
            }
        }

        .accordion-brand {
            padding-left: 75px;

            @include mobile {
                padding-left: 50px;
            }

            h4 {
                cursor: pointer;
                font-size: 19px;
                font-weight: normal;
                position: relative;

                &::before {
                    background-color: $gray-bg;
                    background-image: url('../media/accordion-plus.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    content: '';
                    position: absolute;
                    left: -75px;
                    top: -15px;
                    width: 50px;
                    height: 50px;
                    @include transition-fast;

                    @include mobile {
                        top: 0px;
                        left: -50px;
                        width: 35px;
                        height: 35px;
                    }
                }

                &:not(.collapsed)::before {
                    background-color: $red;
                    background-image: url('../media/accordion-minus.svg');
                }
            }

            p {
                color: #707070;

                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }

            >.card {
                overflow: visible;
            }

            .card {
                background-color: transparent;
                border: none;
            }

            .card-header {
                background-color: transparent;
                border: none;
                padding: 30px 0;
            }

            .card-body {
                padding: 20px 0;
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        ul {

            > li {
                background: url(../media/list-bullet-arrow.svg) top 5px left no-repeat;
                padding-left: 30px;
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                > ul {
                    margin-top: 20px;

                    > li {
                        background: url(../media/list-bullet-square.svg) top 10px left no-repeat;
                        padding-left: 30px;
                        position: relative;

                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }

    .download {
        margin-bottom: 150px;

        @include mobile {
            margin-bottom: 75px;
        }

        .section-title {

            @media (max-width: 1199px) {
                margin-bottom: 30px;
            }
        }

        .download-files {

            &:not(:last-child) {

                @include mobile-xs {
                    margin-bottom: 20px;
                }
            }
        }

        ul {

            li {
                background-image: url('../media/list-bullet-download.svg');
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 25px;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                a {
                    color: #707070;
                    text-decoration: underline;
                    @include transition-fast;

                    &:hover {
                        color: $almost-black;
                    }
                }
            }
        }
    }
}
