@import "vars";

body {
    color: $black;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    position: relative;
    background-image: url('../media/investors-tlo.svg');
    background-repeat: repeat;
    background-size: 130px 357px;

    p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    .section-title {
        color: $almost-black;
        font-size: 42px;
        font-weight: 600;
        line-height: 1.5;
        padding-bottom: 30px;
        position: relative;

        @include mobile() {
            font-size: 32px;
        }

        &::after {
            background-color: $red;
            border-radius: 2px;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 45px;
            height: 4px;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .list-brand {

        ul {

            > li {
                background: url(../media/list-bullet-arrow.svg) top 5px left no-repeat;
                padding-left: 30px;
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                > ul {
                    margin-top: 20px;

                    > li {
                        background: url(../media/list-bullet-square.svg) top 10px left no-repeat;
                        padding-left: 30px;
                        position: relative;

                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }

    .list-brand {

        > li {
            background: url(../media/list-bullet-arrow.svg) top 5px left no-repeat;
            padding-left: 30px;
            position: relative;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            > ul {
                margin-top: 20px;

                > li {
                    background: url(../media/list-bullet-square.svg) top 10px left no-repeat;
                    padding-left: 30px;
                    position: relative;

                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    a {
        color: black;
        text-decoration: none;

        &:hover {
            color: black;
            text-decoration: none;
        }
    }

    .ico-btn {
        background-color: $light-red;
        color: white;
        display: inline-block;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        padding: 20px 40px;
        padding-right: 90px;
        position: relative;
        @include transition-fast();

        @include mobile() {
            padding: 10px 20px;
            padding-right: 70px;
        }

        &:hover {
            transform: scale(.95);
            color: white;
        }

        &::after {
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-color: $red;
            height: 100%;
            width: 50px;
            position: absolute;
            right: 0;
            top: 0;
        }

        &.play-btn {
            &::after {
                background-image: url("../media/play.svg");
            }
        }

        &.more-btn {
            &::after {
                background-image: url("../media/arrow-down.svg");
            }
        }

        &.next-btn {
            &:after {
                background-image: url("../media/arrow-right.svg");
            }
        }

        &.download-btn {
            &:after {
                background-image: url("../media/paper-clip.svg");
            }
        }

        &.right-btn {
            float: right;
        }
    }

    .red {
        color: $red;
    }

    main {
        padding-top: 120px;

        @include mobile {
            padding-top: 156px;
        }

        span.copyright {
            display: block;
            font-weight: 600;
            color: $gray;
            font-size: 16px;
            text-align: center;
            @include mobile() {
                margin-top: 20px;
            }
        }
    }

    .modal-video {
        z-index: 200;
    }

    @import "header";
    @import "homepage";
    @import "footer";
}
