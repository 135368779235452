$red: #892301;
$medium-red: #be150c;
$light-red: #e51e13;
$light-gray: #efefef;
$dark-gray: #605f5f;
$blue-bg: #152566;
$gray-bg: #f7f7f7;
$almost-black: #111111;
$orange: #ff4e39;
$tabs-gray: rgba(9, 9, 19, 0.3);
$gray: #ababab;
$green: #65b9aa;
$white: #ffffff;
$black: #000000;

$tablet-max: 1024px;
$mobile-max: 767px;
$small-mobile-height-max: 570px;

@mixin mobile-xs {
    @media (max-width: 575px) {
        @content;
    }
}

@mixin small-mobile {
    @media (max-width: $mobile-max) and (max-height: $small-mobile-height-max) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-max) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tablet-max) {
        @content;
    }
}

@mixin laptop-and-greater {
    @media (min-width: 1025px) {
        @content;
    }
}


@mixin transition-fast{
    transition: .2s all ease-in-out;
}
